import Vue from "vue"
import { cookie } from "@Platon/core/functions/cookies"

export const mutations = {
	/**
	 * @param state
	 * @param {PlatonMenu[]} menu
	 */
	setMenu(state, menu) {
		state.menu = menu
	},

	/**
	 * @param state
	 * @param {PlatonMenu[]} menu
	 */
	setCustomMenu(state, menu) {
		state.customMenu = menu
	},

	/**
	 * @param state
	 * @param {boolean} mode
	 */
	setForceUserMode(state, mode) {
		state.forceUserMode = mode
	},

	/**
	 * @param state
	 */
	toggleMenuCollapse(state) {
		let collapse = !localStorage.getItem("menuCollapsed")
		state.menuCollapsed = collapse

		if (collapse) localStorage.setItem("menuCollapsed", "1")
		else {
			localStorage.removeItem("menuCollapsed")
		}
	},

	/**
	 * @param state
	 * @param payload
	 * @param {string} payload.token
	 * @param {boolean} payload.saveLoginPage
	 * @param {User} payload.user
	 */
	doLogin(state, { token, user, saveLoginPage = true }) {
		if (saveLoginPage) localStorage["login_url"] = window.location.href

		state.user = user
		state.isAuth = true

		localStorage["auth_user"] = JSON.stringify(user)
	},

	changeLocale(state, newLocale) {
		state.user.locale = newLocale

		localStorage["auth_user"] = JSON.stringify(state.user)
	},

	doLogout(state) {
		cookie.remove("auth_token")

		localStorage.removeItem("auth_user")
		localStorage.removeItem("login_url")
		localStorage.removeItem("menuCollapsed")
		localStorage.removeItem("auth_token_refresh")

		state.user = null
		state.isAuth = false
	},

	setUserParam(state, { key, value }) {
		Vue.set(state.user, key, value)
	},

	setTheme(state, { theme, color }) {
		state.theme = theme
		state.themeColor = color
		localStorage.setItem("platon_theme", theme)
		localStorage.setItem("platon_theme_color", color)
	}
}
