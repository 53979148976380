import Axios from "axios"
import { cookie } from "@Platon/core/functions/cookies"
import { localeInfo } from "@Platon/core/translations"
import { decodeToBase64, encodeToBase64 } from "./HttpHelpers"
import { API_BASE_URL } from "@Platon/const"
import router from "@Platon/router"

window.Axios = Axios

/**
 * @param {AxiosRequestConfig|{}} options
 */
export default function (options) {
	let { store, ...axiosOptions } = options
	let instance = Axios.create(axiosOptions)

	function logout() {
		if (store) store.commit("platon/doLogout")
	}

	instance.interceptors.response.use(
		(config) => {
			if (config.headers["content-type"] === "application/json+base64") {
				config.data = decodeToBase64(config.data)
			}

			return config
		},
		async (error) => {
			const originalRequest = error.config

			if (originalRequest.headers["Content-Type"] === "application/json+base64") {
				error.response.data = decodeToBase64(error.response.data)
			}

			// if (error.code == "ERR_NETWORK" || originalRequest.data == undefined) router.push({ name: "404" })

			if (error.response.status !== 401) return Promise.reject(error)

			if (originalRequest.url == "/refresh/token") {
				logout()
				return Promise.reject(error)
			}

			if (originalRequest._retry) {
				logout()
				return Promise.reject(error)
			}

			if (localStorage.getItem("auth_token_refresh")) {
				try {
					const res = await instance({
						baseURL: API_BASE_URL,
						url: "/refresh/token",
						headers: {
							"device-id": "Iphone X",
							Authorization: "Bearer " + localStorage.getItem("auth_token_refresh")
						}
					})
				} catch (refreshError) {
					if (refreshError.response.status === 401) {
						// window.location.replace(window.location.origin + "/auth/login")
					}

					originalRequest._retry = (originalRequest._retry || 0) + 1
					return Promise.reject(refreshError)
				}
			}

			return Promise.reject(error)
		}
	)

	instance.interceptors.request.use((config) => {
		let token = cookie.get("auth_token")
		const isNeedToAppendToken = typeof config.useAuthorization === "undefined" ? true : config.useAuthorization
		config.headers["Accept-Language"] = localeInfo.locale
		config.headers["ngrok-skip-browser-warning"] = "1" // skip browser warning

		if (config.method !== "get") {
			const transferMethod = Vue.prototype.$projectInfo && Vue.prototype.$projectInfo.transfer_method
			const isMultipartRequest = config.data instanceof FormData

			if (!isMultipartRequest && transferMethod) {
				config.data = config.data && encodeToBase64(config.data)
				config.headers["Content-Type"] = `application/json+${transferMethod}`
			}

			if (transferMethod) {
				config.headers["Accept"] = `application/json+${transferMethod}`
			}
		}

		// if (token && isNeedToAppendToken) {
		if (token && isNeedToAppendToken && !config.url.includes("public/") && !config.url.includes("/refresh/token")) {
			config.headers["Authorization"] = `Bearer ${token}`
		}

		return config
	})

	return instance
}
